import axios from "axios";

export const industryHelper = {
    loadByIds
};

async function loadByIds(ids) {
    try {
        if (ids.length === 0) {
            return;
        }

        const json = JSON.stringify({ids: ids});
        const { data } = await axios.post(`/industry/fetch`, json, {
            data: {},
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        });

        return new Map(data.map((obj) => [obj.id, obj]));
    } catch (error) {
        console.log(error)
        return null
    }
}