<template>
  <div class="mb-3">
    <div class="card-custom-header col-sm-12 d-flex justify-content-between py-4">
      <img :src="getSBZLogo()" alt="Image" height="50">
      <b-button @click="Swal.fire('Dostępne wkrótce')" variant="primary" class="w-lg px-5" style="margin-right: 2px">Przejdź do koszyka
      </b-button>
    </div>

    <div class="row-cols-2" v-if="isWordPress()">
      <div class="col-md-6 d-inline-flex justify-content-center status-bg-licence status-text-licence py-1">
        <template v-if="isWordPressPluginActive()">
          <i class="mdi mdi-check-bold mr-2"/>
          Aktywna Licencja
        </template>
        <template v-else>
          <i class="mdi mdi-close mr-2"/>
          Nieaktywna Licencja
        </template>
      </div>

      <div class="col-md-6 d-inline-flex justify-content-center py-1"
           :class="$store.getters['shop/isPairedShop'](shop.id) ? 'status-bg-active-wordpress status-text-active-wordpress' : 'status-bg-inactive-wordpress status-text-inactive-wordpress'">
        <i class="mdi mdi-checkbox-blank-circle mr-2"/>
        {{ $store.getters['shop/isPairedShop'](shop.id) ? "Połączono z Wordpress" : "Nie połączono z Wordpress" }}
      </div>
    </div>

    <div class="card-custom-body">
      <div class="row-cols-2 d-flex flex-wrap">
        <shop-card-text
            text="Nazwa sklepu:"
            :value="shop.name"
            button-message="Zmień tytuł"
            :callback="() => $emit('edit-shop-element-modal', shop, 'name', 'Nazwa sklepu', 'STRING', 'Zmiana nazwy sklepu')"
        />

        <shop-card-text
            text="Dodatkowe Integracje:"
            value="Brak"
            button-message="Dodaj nowe"
            :callback="() => Swal.fire('Dostępne wkrótce')"
        />

        <shop-card-text
            v-if="!!shop.domain"
            text="Domena:"
            :value="shop.domain"
            button-message="Zmień nazwę domeny sklepu"
            :callback="() => $emit('edit-shop-element-modal', shop, 'domain', 'Domena', 'STRING', 'Zmiana domeny sklepu')"
        />

        <shop-card-text
            v-if="!!shop.emailAddress"
            text="Adres e-mail sklepu:"
            :value="shop.emailAddress"
            button-message="Zmień adres email"
            :callback="() => $emit('edit-shop-element-modal', shop, 'emailAddress', 'Adres E-mail sklepu', 'STRING', 'Zmiana adresu E-mail sklepu')"
        />

        <shop-card-text
            text="Limit produktów:"
            :value="shop.productLimit.toString()"
            button-message="Wybierz limit produktów"
            :callback="() => $emit('open-shop-product-limit-modal', shop.id)">

          <template v-slot:footer>
            <span v-if="getProductLimit()">Wygasa: <br /><b class="text-gray-900">{{ marketplaceOrderHelper.getExpireTime(getProductLimit()) }}</b></span>
          </template>
        </shop-card-text>

<!--        <shop-card-text-->
<!--            :value="dateUtil.asDate(getWordPressPlugin().currentPeriodEnd)"-->
<!--            text="Data ważności limitu produktów:"-->
<!--        />-->

        <shop-card-text
            text="Właściciel sklepu:"
            :value="user.email"
            button-message="Wystaw sklep na sprzedaż"
            :callback="() => Swal.fire('Dostępne wkrótce')"
        />

        <shop-card-text
            v-if="!!getIndustry(shop.industryId)" text="Branża sklepu:"
            :value="getIndustry(shop.industryId)?.name || ''"
            button-message="Zmień branżę"
            :callback="() => $emit('edit-shop-element-modal', shop, 'industryId', 'Branża sklepu', 'INDUSTRY', 'Zmiana branży sklepu')"
        />

        <shop-card-text
            text="Osoba wspierająca:"
            value="Brak"
            button-message="Wybierz osobę wpierającą"
            :callback="() => Swal.fire('Dostępne wkrótce')"
        />

        <shop-card-text
            text="Silnik sklepu:"
            :value="$t($store.getters['engine/getEngineName'](shop.engineId))"
            button-message="Zmień silnik sklepu"
            :callback="() => $emit('edit-shop-element-modal', shop, 'engineId', null, 'ENGINE', 'Zmiana silnika sklepu')"
        />

        <shop-card-text
            text="Rynek sklepu:"
            :value="$t($store.getters['market/getMarketName'](shop.marketId))"
        />

        <shop-card-text
            v-if="$store.getters['engine/isWordPress'](shop.engineId)"
            :value="isWordPressPluginActive() ? 'PRO' : 'FREE'"
            text="Wersja wtyczki:"
        >
          <!--          <a href="/ECAT-ECOMMERCE.zip" class="btn btn-sm btn-success clickable-element">-->
          <!--            <i class="fa fa-download"/>-->
          <!--          </a>-->

          <template v-slot:button>
            <button
                v-if="canBuyWordPressPlugin()"
                class="btn btn-sm btn-primary mx-2"
                @click="$emit('buy-wordpress-plugin-modal', shop.id)">
              <i class="fa fa-shopping-cart"/>
            </button>
          </template>
        </shop-card-text>

        <shop-card-text
            v-if="$store.getters['engine/isWordPress'](shop.engineId) && isWordPressPluginActive()"
            :value="dateUtil.asDate(getWordPressPlugin().currentPeriodEnd)"
            text="Data ważności wtyczki PRO:"
        />
      </div>

      <div class="row-cols-1">
        <div class="col-sm-12 card-custom-image border border-1 d-flex flex-row">
          <div class="w-50 d-flex flex-column justify-content-center">
            <div class="card-custom-type">
              Logo sklepu w strefie bezpiecznych <br>zakupów:
            </div>
            <div class="card-custom-button">
              <a class="btn btn-link my-2 p-0 font-size-14" @click="Swal.fire('Dostępne wkrótce')">
                Zmień Logo
              </a>
            </div>
          </div>
          <div class="w-50 d-flex justify-content-end align-items-center">
            <div class="square_shape d-flex align-items-center justify-content-center">
              <img src="@/assets/images/sbz.svg" alt="Image" height="40">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-custom-footer border border-1">
      <div class="d-flex flex-row flex-wrap justify-content-between buttons">
        <b-button :href="`/dashboard/products?shopId=${shop.id}`" variant="success" class="w-32">
          <i class="mdi mdi-plus mr-2"/>
          Dodaj Nowe Produkty
        </b-button>

        <b-button @click="$emit('open-shop-create-category-modal')" variant="success" class="w-32"
                  :disabled="!(isWordPress() && $store.getters['shop/isPairedShop'](shop.id))">
          <i class="mdi mdi-plus mr-2"/>
          Dodaj Kategorie
        </b-button>

        <b-button variant="success" class="w-32" @click="Swal.fire('Dostępne wkrótce')">
          <i class="mdi mdi-plus mr-2"/>
          Dodaj osobę wspierającą
        </b-button>

        <b-button :href="`/dashboard/user/shop/products?shopId=${shop.id}`" variant="outline-success" class="w-32">
          Produkty dodane do sklepu
        </b-button>

        <b-button :href="`/dashboard/shop/categories?shopId=${shop.id}`" variant="outline-success" class="w-32"
                  :disabled="!isWordPress()">
          Kategorie dodane w sklepie
        </b-button>

        <b-button @click="$emit('edit-shop-element-modal', shop, null, null, 'INTEGRATIONS', 'Marketing')"
                  variant="outline-success" class="w-32">
          Marketing
        </b-button>

        <b-button :href="`/dashboard/user/shop/products?shopId=${shop.id}&productsOnMarketPlace=true`" variant="warning" class="w-49">
          Produkty w marketplace
        </b-button>

        <b-button @click="$emit('open-shop-product-limit-modal', shop.id)" variant="primary" class="w-49">Zwiększ limit
          produktów
        </b-button>

        <template v-if="isWordPress()">
          <b-button @click="$emit('buy-wordpress-plugin-modal', shop.id)" v-if="canBuyWordPressPlugin()"
                    variant="success" class="w-100">Zakup wtyczki ECAT PRO
          </b-button>

          <a href="/ECAT-ECOMMERCE.zip" class="btn btn-info clickable-element w-100">Pobierz wtyczkę</a>
        </template>

        <b-button @click="$emit('delete-shop-modal', shop)" variant="danger" class="w-100" style="margin-bottom: 0 !important;" :disabled="true">Usuń sklep</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ShopCardText from "./shop-card-text.vue";
import {dateUtil} from "@/helpers/date-util";
import Swal from "sweetalert2";
import logoSBZDark from "@/assets/images/logo-sbz-dark.png";
import logoSBZLight from "@/assets/images/logo-sbz-light.png";
import {marketplaceOrderHelper} from "@/helpers/marketplace-order-helper";

export default {
  components: {ShopCardText},

  props: {
    shop: {
      type: Object,
      required: true
    },

    industries: {
      type: Map,
      required: true
    },

    wordPressPlugins: {
      type: Map,
      required: true
    },

    productLimits: {
      type: Map,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    Swal() {
      return Swal
    },

    dateUtil() {
      return dateUtil
    },

    marketplaceOrderHelper() {
      return marketplaceOrderHelper
    }
  },

  methods: {
    getSBZLogo() {
      if (this.$store.getters['theme/isThemeDark']) {
        return logoSBZDark
      }

      return logoSBZLight
    },

    isWordPress() {
      return this.$store.getters['engine/getEngineName'](this.shop.engineId) === 'engine.wordpress'
    },

    getIndustry(id) {
      if (!this.industries) {
        return null
      }

      return this.industries.get(id) || null;
    },

    getProductLimit() {
      if (!this.productLimits || !this.shop.productLimitMarketplaceOrderId) {
        return null
      }

      return this.productLimits.get(this.shop.id) || null;
    },

    getWordPressPlugin() {
      if (!this.wordPressPlugins || !this.shop.wordpressPluginMarketplaceOrderId) {
        return null
      }

      return this.wordPressPlugins.get(this.shop.id) || null;
    },

    canBuyWordPressPlugin() {
      const wordPressPlugin = this.getWordPressPlugin()
      return this.isWordPress() && (!wordPressPlugin || (wordPressPlugin && wordPressPlugin.internalStatus !== "ACTIVE"))
    },

    isWordPressPluginActive() {
      const wordPressPlugin = this.getWordPressPlugin()
      return this.isWordPress() && wordPressPlugin && wordPressPlugin.internalStatus === "ACTIVE"
    }
  }

}
</script>