<template>
  <b-modal
      v-model="visibleModal"
      hide-footer
      :title="`${title}`"
      title-class="font-18"
      @esc="hideModal"
      @hide="hideModal">

    <label v-if="elementName">{{ elementName }}</label>
    <template v-if="type === 'INT'">
      <input v-model.number="item[element]" type="number" class="form-control" />
    </template>
    <template v-else-if="type === 'STRING'">
      <input v-model="item[element]" @input="event => checkDomainIsExists(event.target.value)" type="text" class="form-control" :class="{ 'is-invalid': element === 'domain' && shopWithThisDomainAlreadyExists }" />
      <div v-if="element === 'domain' && shopWithThisDomainAlreadyExists" class="invalid-feedback">Taka domena jest już używana</div>
    </template>
    <template v-else-if="type === 'INDUSTRY'">
      <ecat-multiselect
          :set-value="item[element]"
          @change="value => item[element] = value"
          :can-unselect="true"
          save-id="id"
          view-id="name"
          placeholder="Wybierz branże"
          fetch-one="/industry"
          load-url="/industry/list/pagination"
          query-url="/industry/by-name"
          list-name="industries"
          param="name"
      />
    </template>
    <template v-else-if="type === 'ENGINE'">
      <div v-if="$store.getters['engine/getEngines']" class="form-group">
        <label>{{  $t('message.engine') }}</label>
        <multiselect
            v-model="item[element]"
            :custom-label="id => $t($store.getters['engine/getEngineName'](id))"
            :options="$store.getters['engine/getEnginesIds']"
            placeholder="Wybierz silnik"
            :show-labels="false"
            :allow-empty="false"
        />
      </div>

      <template v-if="$store.getters['engine/getEngineName'](item.engineId) === 'engine.wordpress'">
        <div class="form-group">
          <label for="domain">{{  $t('message.domain') }}</label>
          <input
              id="domain"
              v-model="item.domain"
              @input="event => checkDomainIsExists(event.target.value)"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': ($v.item.domain.$error) || requestSend && shopWithThisDomainAlreadyExists }"
          />
          <div v-if="!$v.item.domain.required" class="invalid-feedback">{{ $t('message.required')}}</div>
          <div v-if="!$v.item.domain.domain" class="invalid-feedback">{{ $t('user-shops.create-shop.is-domain')}}</div>
          <div v-if="requestSend && shopWithThisDomainAlreadyExists" class="invalid-feedback">Taka domena jest już używana</div>
        </div>

        <div class="form-group">
          <label for="email">{{  $t('message.shop-email') }}</label>
          <input
              id="email"
              v-model="item.emailAddress"
              type="email"
              name="email"
              class="form-control"
              :class="{ 'is-invalid': $v.item.emailAddress.$error }"
          />
          <div v-if="!$v.item.emailAddress.required" class="invalid-feedback">{{ $t('message.required')}}</div>
          <div v-if="!$v.item.emailAddress.email" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div v-if="$store.getters['engine/getEngineName'](item.engineId) !== 'engine.virtual'" class="form-group">
          <ecat-multiselect
              :set-value="item.industryId"
              @change="value => item.industryId = value"
              save-id="id"
              view-id="name"
              :label="$t('message.industry')"
              placeholder="Wybierz branże"
              fetch-one-url="/industry"
              load-url="/industry/list/pagination"
              query-url="/industry/by-name"
              list-name="industries"
              param="name"
              :class="{ 'is-invalid': $v.item.industryId.$error }"
          />
          <div v-if="!$v.item.industryId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>
      </template>
    </template>

    <template v-else-if="type === 'INTEGRATIONS'">
      <div class="form-group">
        <label for="google_analytics">Google Analytics</label>
        <input
            id="google_analytics"
            v-model="item.googleAnalytics"
            type="text"
            name="google_analytics"
            class="form-control" />
      </div>

      <div class="form-group">
        <label for="google_tag_manager">Google Tag Manager</label>
        <input
            id="google_tag_manager"
            v-model="item.googleTagManager"
            type="text"
            name="google_tag_manager"
            class="form-control" />
      </div>

      <div class="form-group">
        <label for="facebook_pixel">{{  $t('user-shops.facebook-pixel') }}</label>
        <input
            id="facebook_pixel"
            v-model="item.facebookPixel"
            type="text"
            name="facebook_pixel"
            class="form-control" />
      </div>
    </template>

    <div class="text-center">
      <b-button class="mx-2" variant="success" @click="editShop">
        Zapisz
      </b-button>

      <b-button variant="danger" class="my-3" @click="hideModal">
        {{ $t('message.cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "../../helpers/error-catcher";
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect},
  
  data() {
    return {
      item: null,
      title: "",
      visibleModal: false,
      element: "",
      elementName: "",
      type: "",
      callback: null,

      shopWithThisDomainAlreadyExists: false,
      requestSend: false,
    }
  },

  validations() {
    if (this.type === "ENGINE") {
      return {
        item: {
          domain: {
            required,
            domain: function (value) {
              return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value)
            }
          },
          emailAddress: {required, email},
          industryId: {required}
        }
      }
    }

    return {}
  },
  
  methods: {
    hideModal() {
      this.visibleModal = false
      this.item = null
      this.title = ""
      this.element = ""
      this.elementName = ""
      this.type = ""
      this.requestSend = false
    },

    openModal(item, element, elementName, type, title, callback = null) {
      this.item = Object.assign({}, item)
      this.element = element
      this.title = title
      this.elementName = elementName
      this.type = type
      this.visibleModal = true
      this.callback = callback
    },

    editShop() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.item);
      axios.put(`/shop`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        const lastIndustryId = this.item.industryId
        this.item = data

        if (lastIndustryId !== data.industryId) {
          this.loadIndustry()
        }

        this.$emit("update-shop", this.item)
        this.$root.$emit("load-paired-shops")
        this.hideModal()
        Swal.fire("Sukces!", "Zaktualizowano informacje o sklepie", "success").then(() => {
          if (this.callback) {
            this.callback()
          }
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    async checkDomainIsExists(domain) {
      if (this.element !== "domain" && this.element !== "engineId" || !domain) {
        this.requestSend = false
        return
      }

      this.requestSend = true
      try {
        const {data} = await axios.get(`/shop/user/exists-by-domain/${domain}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        const { status } = data
        this.shopWithThisDomainAlreadyExists = status
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  }
  
}
</script>